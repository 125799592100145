/* Generated by Glyphter (http://www.glyphter.com) on  Thu Nov 26 2020*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-carte:before{content:'\0041';}
.icon-profil:before{content:'\0042';}
.icon-active-card:before{content:'\0043';}
.icon-arrow:before{content:'\0044';}
.icon-envoi-mail:before{content:'\0045';}
.icon-croix:before{content:'\0046';}
.icon-coche:before{content:'\0047';}
.icon-info:before{content:'\0048';}
.icon-editer:before{content:'\0049';}
.icon-cross:before{content:'\004a';}
.icon-check:before{content:'\004b';}